import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout/layout"
import Seo from "../components/seo"
import Hero from "../components/Index/hero"
import BestServices from "../components/Index/bestServices"
import HowItWorks from "../components/Index/howItWorks"
import PopularServices from "../components/Index/popularServices"
import WhyHire from "../components/Index/whyHire"
import CompanyReviewSection from "../components/Index/companyReviewSection"
import WhatHelp from "../components/Index/whatHelp"
import Faq from "../components/Index/faq"
import Description from "../components/Index/description"
import Footer from "../components/Layout/footer"
import Navbar from "../components/Layout/navbar"
import { useEffect } from "react"
import { setCityImage } from "../services/imageHelper"

const { slugify } = require("../services/slugHelper")

const CityTemplate = ({ data, pageContext }) => {
  const city = data.allCity.nodes[0]

  useEffect(() => {
    setCityImage(
      city,
      `${process.env.GATSBY_WRANGLER_URL}/${city.countryCode}/${
        city.regionCode
      }/${city.name.toLowerCase()}.jpg`
    )
  })

  const basePath = "/" + (city.countryCode + "/" + city.regionCode + "/" + slugify(city.name)).toLowerCase()

  return (
    <Layout page="country">
      <Navbar locationName={city.name} />
      <Seo title={"Best " + city.name + " Home Services - Handyman, Lawn & More"} />
      <Hero locationName={city.name} basePath={basePath} />
      <BestServices basePath={basePath} />
      <HowItWorks />
      <PopularServices locationName={city.name} basePath={basePath} />
      <WhyHire />
      <CompanyReviewSection />
      <WhatHelp />
      <Faq />
      <Description />
      <Footer locationName={city.name} basePath={basePath} />
    </Layout>
  )
}

export default CityTemplate

export const regionQuery = graphql`
  query ($name: String!, $regionCode: String!, $countryCode: String!) {
    allCity(
      filter: { name: { eq: $name }, regionCode: { eq: $regionCode }, countryCode: { eq: $countryCode } }
    ) {
      nodes {
        id
        name
        regionCode
        countryCode
      }
    }
  }
`
